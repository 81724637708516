$textColorDark: #d4d3b7;
$backgroundColorDark: rgba(0, 0, 0, 0.4);
$headColorDark: wheat;
$modalBackgroundColorDark: black;


$textColor: rgb(75, 75, 75);
$backgroundColor: rgba(222, 222, 222, 0.729);
$headColor: black;
$modalBackgroundColor: white;

$colorAccent: #fba08b;
$darkGray: #292929;
$base-duration: 500ms;

.darkTheme {
    .modal {
        background-color: $backgroundColorDark;
    }

    .header {
        span {
            font-weight: 1000;
            text-align: justify;
            color: $headColorDark;
        }

        svg {
            fill: $headColorDark;
        }
    }

    .modalContent {
        color: $textColorDark;
        background-color: $modalBackgroundColorDark;
    }

    .clearPII {
        color: $headColor;
        background-color: $modalBackgroundColor;
    }

    .active {
        background-color: $headColor;
    }

    .button {
        color: $headColor;
        background-color: $headColorDark;

        &:before {
            background-color: rgb(239, 239, 239);
        }
    }

    .button.active {
        color: $headColorDark;
        background-color: $headColor;
    }

    .button.finished {
        color: $headColorDark;
        background-color: $headColor;
    }
}

.modal.show {
    display: block;
    animation: blur-in 500ms forwards;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $backgroundColor;
    backdrop-filter: blur(4px) opacity(0);

    svg {
        animation: fade-in 500ms forwards;
        margin-right: 16px;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }

    70% {
        opacity: 1;
        transform: scale(1.2);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }

}

@keyframes blur-in {
    0% {
        backdrop-filter: blur(0px);
    }

    25% {
        backdrop-filter: blur(2px);
    }

    50% {
        backdrop-filter: blur(4px);
    }

    75% {
        backdrop-filter: blur(6px);
    }

    100% {
        backdrop-filter: blur(8px);
    }
}

.header {
    font-size: larger;
    font-family: "Jost", sans-serif;
    display: flex;
    align-items: center;

    span {
        font-weight: 1000;
        text-align: justify;
        color: $headColor;
    }

    svg {
        fill: $headColor;
    }
}

/* Modal Content/Box */
.modalContent {
    background-color: $modalBackgroundColor;
    color: $textColor;
    font-family: 'Courier New', Courier, monospace;
    margin: 10% auto;
    /* 15% from the top and centered */
    padding: 20px;
    width: 80%;
    max-width: 380px;
    border-radius: 12px;
    animation: glow 1s ease-in-out infinite alternate;

    svg {
        fill: $headColor;
    }

    P {
        padding: 20px;
        text-align: justify;
        font-family: "Jost", sans-serif;
    }

    .footer {
        font-size: 12px;
        padding: 20px;
    }

    /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

@keyframes glow {
    from {
        box-shadow: 0 0 20px 2px wheat;
    }

    to {
        box-shadow: 0 0 20px 1px $textColorDark;
    }
}

.flex {
    display: flex;
}

.flexWrapEvenly{
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap-reverse;
} 

.hide {
    display: none;
}

button.submit {
    animation: fade-in 500ms forwards;
    padding: 10px 20px;
    border: none;
    margin: auto;
    background: wheat;
    border-radius: 8px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    color: black;
    width: 80vw;
    max-width: 260px;
}

button.clearPII {
    animation: fade-in 500ms forwards;
    padding: 10px 20px;
    border: none;
    margin: auto;
    background: $headColor;
    border-radius: 8px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    color: white;
    width: 80vw;
    max-width: 260px;
}

.button {
    animation: fade-in 500ms forwards;
    padding: 10px 20px;
    border: none;
    margin: auto;
    background: $headColor;
    border-radius: 8px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    color: $textColor;
    width: 80vw;
    max-width: 260px;
    color: white;

    &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        opacity: 0.4;
        background-color: rgb(0, 0, 0);
    }

    span {
        position: absolute;
        line-height: 0;

        img {
            transform-origin: center center;
        }

        &:nth-of-type(1) {
            top: 50%;
            transform: translateY(-50%);
        }

        &:nth-of-type(2) {
            top: 100%;
            transform: translateY(0%);
            font-size: 24px;
        }

        &:nth-of-type(3) {
            display: none;
        }
    }
}

.active.button {
    color: $headColor;
}

.active {
    background-color: white;

    &:before {
        width: 100%;
        transition: width 5s cubic-bezier(0.1, 0.1, 0.2, 1.0);
    }

    span {

        &:nth-of-type(1) {
            top: -100%;
            transform: translateY(-50%);
        }

        &:nth-of-type(2) {
            top: 50%;
            transform: translateY(-50%);

            img {
                animation: loading $base-duration linear infinite;
            }
        }

        &:nth-of-type(3) {
            display: none;
        }
    }
}

.finished.button {
    color: $headColor;
}

.finished {
    background-color: rgb(255, 255, 255);

    .submit {
        display: none;
    }

    .check {
        display: block !important;
        font-size: 24px;
        animation: scale 0.5s linear;

        i {
            transform-origin: center center;
        }
    }

}

@keyframes loading {
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes scale {
    0% {
        transform: scale(10);
    }

    50% {
        transform: scale(0.2);
    }

    70% {
        transform: scale(1.2);
    }

    90% {
        transform: scale(0.7);
    }

    100% {
        transform: scale(1);
    }
}