.dark {

    .content {
        background-color: black;
    }

    .blogWrapper {
        background-color: rgb(29, 29, 49);

        .item {
            border: solid whitesmoke 1px;
            color: aliceblue;

            &:hover {
                background: #120f1c;

                img {
                    transform: translateY(-40px);
                }
            }

            img {
                border: solid whitesmoke 1px;
            }
        }
        .verticalItem {
            button{
                background-color: white;
            }
        }
    }
}

.content {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 100px;
}
@for $i from 1 through 10 {
    .tagsWrapper .verticalItem:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.8}s;
    }
}
@keyframes fadein {
    from {
        opacity: 0;
        transform: scale(0.6);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}
.blogWrapper {
    border-radius: 40px 40px 0 0;
    background: var(--search-area-bg);
    height: 100%;
    overflow: scroll;
    box-shadow: inset 0px 5px 12px -8px rgba(0, 0, 0, 0.75);

    .verticalItem {
        display: flex;
        flex-direction: column;
        animation: fadein 500ms ease;
        animation-fill-mode: both;
        button{
            background-color: black;
            border: none;
            width: 100px;
            z-index: 1;
            color: transparent;
            transition: 1s all;
            cursor: pointer;
            transform: translateY(-50%) scaleY(0.2) translateX(50%);
        }
        &:hover{
            button{
                background-color: whitesmoke;
                color: #000;
                height: 40px;
                border: solid #000 1px;
                margin-right: 30px;
                border-radius: 8px;
                transform: translateX(20%) translateY(-50%) scale(1);
            }
        }
    }

    .item {
        min-width: 300px;
        width: 90vw;
        margin: 32px auto;
        display: flex;
        border: solid black 1px;
        color: black;
        border-radius: 14px;
        padding: 4px;
        margin-left: 32px;
        margin-bottom: 0;
        transition: all 1s ease;

        &:hover {
            background: #fff;

            img {
                transform: translateY(-40px);
            }
        }

        img {
            transition: all 1s ease;
            max-width: 150px;
            width: 35vw;
            border-radius: 12px;
            z-index: 1;
            margin: auto 0;
            margin-left: -20px;
            margin-top: auto;
            border: solid black 1px;
        }

        div {
            min-width: 200px;
            width: 60vw;
            z-index: 0;
        }

        h3 {
            text-align: center;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            margin: 8px auto;
            padding-left: 12px;
            font-size: x-large;
        }

        p {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            margin: 0 auto;
            padding: 12px;
            padding-bottom: 24px;
            text-align: justify;
        }
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.Blogs {
    display: flex;
    flex-direction: column;
    height: 100vh;
}