@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500|Jost:400,500,600&display=swap");

body {
  box-sizing: border-box;
  margin: 0;
}

#snackbar {
  visibility: hidden;
  font-family: "Jost", sans-serif;
  visibility: hidden;
  min-width: 250px;
  background-color: wheat;
  color: black;
  text-align: center;
  border-radius: 4px;
  padding: 16px;
  position: fixed;
  z-index: 99999999;
  left: 0%;
  bottom: 30px;
  font-size: 17px;
  margin: 0 50px;
  right: 0;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadeinSnackbar 0.5s, fadeoutSnackbar 0.5s 2.5s;
  animation: fadeinSnackbar 0.5s, fadeoutSnackbar 0.5s 7.5s;
}

@keyframes fadeinSnackbar {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeoutSnackbar {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}



